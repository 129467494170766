export const Success = ({width = 50, height = 50}) => {
  return (
    <svg className="svg-icon"
         width={width}
         height={height}
         viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M510.8 103.9c-225.5 0-408.4 182.8-408.4 408.4 0 225.5 182.8 408.4 408.4 408.4 225.5 0 408.4-182.8 408.4-408.4 0-225.5-182.8-408.4-408.4-408.4z m229.8 310.9L499.7 655.7c-6.4 6.4-14.7 9.5-23 9.5s-16.7-3.1-23-9.5L316.2 518.3c-12.7-12.7-12.7-33.2 0-45.9 12.7-12.7 33.2-12.7 45.9 0l114.5 114.5 218-218c12.7-12.7 33.2-12.7 45.9 0 12.8 12.7 12.8 33.3 0.1 45.9z"
        fill="#3418d7"/>
    </svg>
  )
}

import React from "react";
import validator from "validator";

export const Input = ({label, type = 'text', value = '', onChange, validation = [], ...rest}) => {

  const [innerValue, setInnerValue] = React.useState(value)
  const [error, setError] = React.useState(null)
  const wasFocused = React.useRef(false)

  const onValueChange = (event) => {
    setInnerValue(event.target.value)
  }

  const validate = React.useCallback(() => {
    const validationData = validation.reduce((acc, rule) => {
      if (!validator[rule.method](innerValue, ...rule.arguments)) {
        acc.push(rule.message)
      }
      return acc
    }, [])

    return validationData.length > 0 ? validationData[0] : null
  },[innerValue])

  React.useEffect(() => {
    if (!wasFocused.current) return
    const err = validate()
    setError(err)
    onChange && onChange({value: innerValue, error: err})
  }, [innerValue])


  return (
    <div>
      <label className="block text-sm font-medium leading-6 text-gray-900">{label}</label>
      <div className="mt-2">
        <input {...rest} onFocus={() => {wasFocused.current = true}} type={type} onChange={onValueChange} required className="block w-full rounded-md border-0 p-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6" />
        <span className="block w-full rounded-md border-0 p-1 text-red-500 shadow-sm text-xs leading-6">{error}</span>
      </div>
    </div>
  )
}

import React from "react";
import {Input, Button, Success} from "./components";
import Logo from './logo.svg'
import './App.css';
import {Request} from "./utils/Request";

const PASSWORD_SEED = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/

const PASSWORD_VALIDATION_OPTIONS = [
  {
    method: "matches",
    arguments: [PASSWORD_SEED],
    message: "Please use at least 8 letters, numbers and at least one special character!",
  }
]

const App = () => {

  const [fields, setFields] = React.useState({password: {value: '', error: null}, passwordConfirmation: {value: '', error: null}})
  const [loading, setLoading] = React.useState(false)
  const [error, setError] = React.useState(null)
  const [displayError, setDisplayError] = React.useState(false)
  const [errorDirection, setErrorDirection] = React.useState('in')
  const [success, setSuccess] = React.useState(false)

  const token = React.useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get('token')
  }, [])

  const errorAnimationClasses = React.useMemo(() => {
    const classes = 'animate-fade-up animate-once animate-duration-[600ms] animate-ease-in-out'
    if (errorDirection === 'out') return `${classes} animate-reverse`
    if (errorDirection === 'in') return classes
    return ''
  }, [errorDirection])

  React.useEffect(() => {
    if (errorDirection === 'out') setTimeout(() => {setDisplayError(false)}, 600)
    if (errorDirection === 'in') setTimeout(() => {setErrorDirection('active')}, 600)
  }, [errorDirection])


  const isValid = React.useMemo(() => {
    return Object.keys(fields).map((key) => {
      const field = fields[key]
      return field.value !== '' && !field?.error
    }).filter(fieldError => !fieldError).length === 0
  }, [fields])

  const CONFIRM_VALIDATION_OPTIONS = React.useMemo(() => {
    return [{
      method: 'equals',
      arguments: [fields?.password?.value || ''],
      message: "Confirm password does not match!",
    }]
  }, [fields?.password?.value])

  const setValues = (key) => (value) => {
    setFields(prev => {
      return {...prev, [key]: value}
    })
  }

  const request = React.useCallback(async (data) => {
    const requestPayload = {...data, token}
    try {
      const result = await Request.POST(requestPayload)
      setSuccess(result.success)
    } catch (e) {
      setError(e)
      setDisplayError(true)
      setErrorDirection('in')
    } finally {
      setLoading(false)
    }
  }, [token])

  const onSubmit = async () => {
    setLoading(true)
    const data = Object.entries(fields).reduce((acc, [key, data]) => {
      acc[key] = data.value
      return acc
    }, {})
    await request(data)
  }

  return <>
    <div className="flex min-h-screen flex-col justify-center align-middle px-6 py-12 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-sm">
          <img className="mx-auto h-10 w-auto" src={Logo} alt="Your Company" />
          <h2 className="mt-4 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Reset your password</h2>
      </div>
      {success && (
        <>
          <div className={'animate-fade-down animate-once animate-duration-[600ms] animate-ease-in-out items-center justify-center flex py-6 flex-col'}>
            <Success width={128} height={128} />
          </div>
          <div className={'animate-fade animate-once animate-duration-[600ms] animate-ease-in-out items-center justify-center flex py-2 flex-col'}>
            <p className={'text-md font-semibold text-gray-900'}>Go back to your app and use your new password.</p>
          </div>
        </>
      )}

      {!success && (
        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
          <Input label={"New password"} type={"password"} value={fields.password.value} onChange={setValues('password')} validation={PASSWORD_VALIDATION_OPTIONS} />
          <div className="mt-2"></div>
          <Input label={'Confirm password'} type={'password'} value={fields.passwordConfirmation.value} onChange={setValues('passwordConfirmation')} validation={CONFIRM_VALIDATION_OPTIONS} />
          <div className="mt-2"></div>
          <div>
            <Button onClick={onSubmit} disabled={!isValid} loading={loading}>Submit</Button>
          </div>
        </div>
      )}
      {displayError && (
        <div className={`text-center py-4 lg:px-4 absolute bottom-1 left-3 right-3 flex flex-1 ${errorAnimationClasses}`}>
          <div className="p-3 bg-red-200 items-center rounded-md text-red-600 leading-none flex flex-1" role="alert">
            <span className="font-semibold mr-2 text-left flex-1 text-xs">{error?.message}</span>
            <svg onClick={() => setErrorDirection('out')} className="cursor-pointer fill-current opacity-75 h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <g> <path fill="none" d="M0 0h24v24H0z"/> <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z"/> </g> </svg>
          </div>
        </div>
      )}
    </div>
  </>
}

export default App;

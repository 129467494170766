import React from "react";

export const Button = ({children, onClick, disabled, loading}) => {
  return (
    <button onClick={onClick} disabled={loading || disabled} className="disabled:bg-indigo-500 disabled:cursor-not-allowed   flex w-full justify-center align-middle rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold transition-colors duration-200 leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
      {loading && (
        <div className="svg-loader">
          <svg className="svg-container" height="20" width="20" viewBox="0 0 100 100">
            <circle className="loader-svg bg" cx="50" cy="50" r="45"></circle>
            <circle className="loader-svg animate" cx="50" cy="50" r="45"></circle>
          </svg>
          Loading
        </div>
      )}
      {!loading && children}
    </button>
  )
}

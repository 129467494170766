export class Request {
  static async POST (body) {
    const result = await fetch('https://api.changex.io/api/reset-password', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'Content-Type': 'application/json',
        "X-Locale": 'en',
        'X-Mobile-App': 'website'
      }
    })
    if (result.status === 200) return Promise.resolve({success: true})
    const res = await result.json()
    const error = res?.errors.length > 0 ? res?.errors[0].description : 'Unexpected error'
    return Promise.reject(new Error(error))
  }
}
